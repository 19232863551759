<template>
  <card>
    <div class="custom-container">
      <navigation-top />
      <card class="shadow-none" id="resultados-evaluado">
        <template v-slot:headerTitle>
          <span class="card-title my-2 h4"><b>Resultados por evaluado</b></span>
        </template>
        <exportExcel v-if="listaItems.length > 0" :headers="exportarResultados" :items="listaItems"
          class="d-flex justify-content-end px-5 pt-2" />
        <DataTable :items="listaItems" :headers="resultadosHeaders" :keys="resultadosKeys"
          tableId="datatable_resultadosEvaluacion" />
      </card>
      <navigation-top />
      <card class="shadow-none" id="cargos-aceptados">
        <template v-slot:headerTitle>
          <span class="card-title my-2 h4"><b>Cargos aceptados</b></span>
        </template>
        <exportExcel v-if="listaItemsCargosAceptados.length > 0" :headers="exportarResultadosCargos"
          :items="listaItemsCargosAceptados" class="d-flex justify-content-end px-5 pt-2" />
        <DataTable :items="listaItemsCargosAceptados" :headers="cargosHeaders" :keys="cargosKeys"
          tableId="datatable_cargos" />
      </card>

      <navigation-top />
      <card class="shadow-none" id="control-evaluaciones">
        <template v-slot:headerTitle>
          <span class="card-title my-2 h4"><b>Seguimiento Evaluaciones</b></span>
        </template>
        <exportExcel v-if="listaItemsSeguimientoEvaluaciones.length > 0" :headers="exportarResultadosSeguimiento"
          :items="listaItemsSeguimientoEvaluaciones" class="d-flex justify-content-end px-5 pt-2" />
        <DataTable :items="listaItemsSeguimientoEvaluaciones" :headers="SeguimientoEvaluacionHeaders"
          :keys="SeguimientoEvaluacionKeys" tableId="datatable_control_evaluaciones" />
      </card>
    </div>
  </card>
</template>

<script>
import DataTable from "../../../components/Datatable/DataTable.vue";
import { core } from "../../../config/pluginInit";
import navigationTop from "./navigation-top.vue";
import exportExcel from "../../../components/exportExcel/exportExcel.vue";

export default {
  components: {
    DataTable,
    navigationTop,
    exportExcel
  },
  name: "DataTableContainer",
  data() {
    return {
      usuario: {},
      listaItems: [],
      listaItemsCargosAceptados: [],
      listaItemsSeguimientoEvaluaciones: [],

      // DATOS PARA RESULTADOS POR EVALUADO
      resultadosKeys: [
        "nombreEvaluado",
        "promedioValoracion",
        "cargo",
        "cicloEvaluacionDescripcion",
        "fechaTerminado",
      ],
      resultadosHeaders: [
        "Nombre Evaluado",
        "Promedio % Resultado Evaluación",
        "Cargo",
        "Ciclo Evaluación",
        "Fecha Terminado",
      ],
      exportarResultados: [

        {
          key: "nombreEvaluado",
          header: "Nombre Evaluado"
        },
        {
          key: "promedioValoracion",
          header: "Promedio % Resultado Evaluación"
        },
        {
          key: "cargo",
          header: "Cargo"
        },
        {
          key: "cicloEvaluacionDescripcion",
          header: "Ciclo Evaluación"
        },
        {
          key: "fechaTerminado",
          header: "Fecha Terminado"
        }
      ],

      // DATOS PARA SEGUIMIENTO DE EVALUACIONES

      SeguimientoEvaluacionHeaders: ["Nombres Evaluador", "Correo Evaluador", "Nombres Evaluado", "Rol Evaluacion", "Estado Evaluacion", "Tipo Evaluacion", "Nombre Ciclo", "Fecha Inicio", "Fecha fin", "Sucursal"],
      SeguimientoEvaluacionKeys: ["nombreEvaluador", "correoEvaluador", "nombreEvaluado", "rolEvaluacion", "estadoEvaluacion", "tipoEvaluacion", "nombreEvaluacion", "fechaInicioCiclo", "fechaFinCiclo", "nombreSucursal"],

      exportarResultadosSeguimiento: [
        {
          key: "nombreEvaluador",
          header: "Nombres Evaluador"
        },
        {
          key: "correoEvaluador",
          header: "Correo Evaluador"
        },
        {
          key: "nombreEvaluado",
          header: "Nombres Evaluado"
        },
        {
          key: "rolEvaluacion",
          header: "Rol Evaluacion"
        },
        {
          key: "estadoEvaluacion",
          header: "Estado Evaluacion"
        },
        {
          key: "tipoEvaluacion",
          header: "Tipo Evaluacion"
        },
        {
          key: "nombreEvaluacion",
          header: "Nombre Ciclo"
        },
        {
          key: "fechaInicioCiclo",
          header: "Fecha Inicio"
        },
        {
          key: "fechaFinCiclo",
          header: "Fecha fin"
        },
        {
          key: "Estado Evaluacion",
          header: "nombreSucursal"
        }
      ],


      // DATOS PARA CARGPS ACEPTADOS
      cargosHeaders: ["Nombres", "Apellidos", "Cargo", "Fecha de creación"],
      cargosKeys: ["nombres", "apellidos", "cargoNombre", "fechaDeCreacion"],
      exportarResultadosCargos: [
        {
          key: "nombres",
          header: "Nombres"
        },
        {
          key: "apellidos",
          header: "Apellidos"
        },
        {
          key: "cargoNombre",
          header: "Cargo"
        },
        {
          key: "fechaDeCreacion",
          header: "Fecha de creación"
        }
      ],

      logoEmpresa: "",
      itemId: null,
    };
  },
  async mounted() {
    this.$isLoading(true);
    core.index();
    this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
    const empresaId = this.usuario.empresa.id;
    await this.getConsultaResultadoEvaluaciones(empresaId);
    await this.getDataCargos(empresaId);
    await this.getDataSeguimientoEvaluaciones(empresaId);
    this.$isLoading(false);
  },

  methods: {
    async getConsultaResultadoEvaluaciones(empresaId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Reportes/ConsultaResultadosEvaluacionesGSE/" + empresaId,
        });

        if (res.length > 0) {
          let groupedByPersonAndCycle = {};

          for (let item of res) {
            let key = `${item.nombreEvaluado}-${item.cicloEvaluacionDescripcion}`;

            if (!groupedByPersonAndCycle[key]) {
              groupedByPersonAndCycle[key] = {
                valoraciones: [],
                detalles: item,
              };
            }
            groupedByPersonAndCycle[key].valoraciones.push(parseFloat(item.valoracion));
          }

          this.listaItems = Object.keys(groupedByPersonAndCycle).map((key) => {
            const valoraciones = groupedByPersonAndCycle[key].valoraciones;
            const total = valoraciones.reduce((sum, val) => sum + val, 0);
            const average = total / valoraciones.length;
            const detalles = groupedByPersonAndCycle[key].detalles;

            return {
              nombreEvaluado: detalles.nombreEvaluado,
              cargo: detalles.cargo,
              promedioValoracion: average.toFixed(2),
              fechaTerminado: detalles.fechaTerminado,
              cicloEvaluacionDescripcion: detalles.cicloEvaluacionDescripcion,
            };
          });
        } else {
          this.listaItems = [];
        }
      } catch (error) {
        this.listaItems = [];
        console.log("err", error);
      }
    },


    async getDataSeguimientoEvaluaciones(empresaId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Reportes/ConsultaEstadoEvaluaciones/" + empresaId,
        });
        if (res.length > 0) {
          this.listaItemsSeguimientoEvaluaciones = res.map(item => {
            return {
              ...item,
              rolEvaluacion: this.getRolEvaluacionLabel(item.rolEvaluacion),
              estadoEvaluacion: this.getEstadoEvaluacionLabel(item.estadoEvaluacion),
              tipoEvaluacion: this.getTipoEvaluacionLabel(item.tipoEvaluacion),
            };
          });
        } else {
          this.listaItemsSeguimientoEvaluaciones = [];
        }
      } catch (error) {
        this.listaItemsSeguimientoEvaluaciones = [];
        console.log("err", error);
      }
    },

    getRolEvaluacionLabel(rolEvaluacion) {
      return rolEvaluacion === 0 ? "AutoEvaluación" :
        rolEvaluacion === 1 ? "Líder" :
          "Líder";
    },

    getEstadoEvaluacionLabel(estadoEvaluacion) {
      return estadoEvaluacion === 0 ? "Finalizada" : "Por responder";
    },

    getTipoEvaluacionLabel(tipoEvaluacion) {
      return tipoEvaluacion === 0 ? "AutoEvaluación" :
        tipoEvaluacion === 1 ? "Autoevalación + Jefe" :
          "AutoEvaluación 360";
    },

    async getDataCargos(empresaId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Reportes/VistaCargosAceptados/" + empresaId,
        });
        if (res.length > 0) {
          this.listaItemsCargosAceptados = res;
        } else {
          this.listaItemsCargosAceptados = [];
        }
      } catch (error) {
        this.listaItemsCargosAceptados = [];
        console.log("err", error);
      }
    },

  },
};
</script>
